<template>
  <Frame :prefix="'กำลังโหลดเนื้อหา'" :background="'white'" :width="'full'">
    <Render v-if="theme?.homepage" :config="theme.homepage"/>
  </Frame>
  <!-- <div>
    <h1>Home Page</h1>

    <div>
      <button @click="showAlert">Show Alert</button>
      <button @click="showPrompt">Show Prompt</button>
      <button @click="showToast">Show Toast</button>
    </div>
    
    <div v-if="todo">
      <NuxtLink :to="'/event/'">Go to Event</NuxtLink>
    </div>
    <div v-else-if="error">
      <p>Error: {{ error.message }}</p>
    </div>
    <div v-else>
      <p>Loading...</p>
    </div>
  </div> -->
</template>

<script setup>

import { inject, watch, onMounted } from 'vue';
import Render from '~/components/builder/Render.vue';
import { useSession } from '~/composables/useSession'; // Import useSession composable
import { useAsyncData } from 'nuxt/app'; // Import useAsyncData
  
// Inject theme from parent or root
const theme = inject('theme');

// Use session composable
const { sessionValid, refreshSession } = useSession();

// Watch for session changes, e.g., to reactively handle session validation
watch(sessionValid, (newVal) => {
  if (!newVal) {
    console.log('Session is invalid, you can perform additional actions here, like redirection.');
  } else {
    console.log('Session is valid.');
  }
});

// Optionally, call refreshSession if needed
// This could be triggered when the component is mounted
refreshSession();

const requestConfig = handleRequest('DU1eYMDG7j8yb199YDPg3');

const { data: todo, error } = await useAsyncData('fetchTodo', () =>
  fetch(`${requestConfig.baseURL}/vote_event`, {
    headers: requestConfig.headers,
  }).then((res) => res.json())
);

const showAlert = async () => {
  const { $alertPrompt } = useNuxtApp();
  await $alertPrompt('alert', { message: 'This is an alert!', title: 'Alert' });
};

const showPrompt = async () => {
  const { $alertPrompt } = useNuxtApp();
  const inputValue = await $alertPrompt('prompt', { message: 'What is your name?', title: 'Input Prompt' });
  console.log('User input:', inputValue);
};

const showToast = async () => {
  const { $alertPrompt } = useNuxtApp();
  await $alertPrompt('toast', { message: 'This is a toast notification!', duration: 2000 });
};

useHead({
  title: theme?.homepage?.seo?.title || 'Homepage',
  meta: [
    { name: 'description', content: theme?.homepage?.seo?.description || 'Homepage.' },
    { name: 'keywords', content: theme?.homepage?.seo?.keywords?.join(', ') || 'Homepage, event, influencers' },
    { property: 'og:image', content: theme?.homepage?.seo?.image || '' },
    { property: 'og:title', content: theme?.homepage?.seo?.title || 'Homepage' },
    { property: 'og:description', content: theme?.homepage?.seo?.description || 'Homepage.' },
  ],
});

</script>
